<template>
  <div class="chat-con">
    <van-tree-select height="100vw" :items="chatsData.category" :main-active-index.sync="active" @click-nav="clickNav">
      <template #content>
          <van-cell-group >
            <template v-for="(item,index) in chatContents">
              <van-cell :key="index" center>
                <template #default>
                    <van-row>
                      <span class="chat-title">
                        {{item.title}}
                      </span>
                    </van-row>
                    <van-row>
                      {{item.content}}
                    </van-row>
                    <van-row>
                    <template>
                      <input :id="'input'+index" type="text" readonly="true" class="copy-input">
                      <van-button type="primary" @click="copyCode(index,item.content)" round size="small">复制</van-button>
                    </template>
                    </van-row>
                </template>
                <!-- 使用 right-icon 插槽来自定义右侧图标 -->
              </van-cell>
            </template>
          </van-cell-group>
      </template>
    </van-tree-select>
  </div>
</template>

<script>
import api from "@/api";
import { Toast } from 'vant';
import chatsData from "@/../public/chats/chats.js"
export default {
  name: 'Home',
  data(){
    return {
      active: 0,
      items: [{ text: '发货' }, { text: '分组 2' }],
      chatsData,
    }
  },
  components: {

  },
  computed:{
    chatContents(){
      let key = this.chatsData.category[this.active]['key']
      return this.chatsData.contents[key]
    }
  },
  methods:{
    clickNav(nav){
      let key = this.chatsData.category[nav]['key']
      console.log('nav',this.chatsData.contents[key])
    },
    copyCode(index,code){
      const input = document.getElementById(`input${index}`);
      input.value = code;
      // 聚焦
      input.focus();
      // 选择需要复制的文本
      if (input.setSelectionRange) {
          input.setSelectionRange(0, input.value.length);
      } else {
          input.select();
      }
      try {
          const result = document.execCommand('copy');
          if(result){
            this.$toast.success('复制激活码成功')
          }
      } catch (e) {
        this.$toast.fail('复制失败，请重试~')
      }
    },


  }
}
</script>
<style lang="scss" scoped>
.chat-con{

  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  .head{
    background-color: #2587ee;
    height: 48px;
    color: #fff;
    margin-bottom:15px;
  }
  .van-radio{
    margin-bottom:5px
  }
  .copy-input{
    width: 1px;
    resize: none;
    border: none;
    outline: none;
    user-select: none;
    color: transparent;
    background: transparent;
  }
  .chat-title{
    font-size: 16px;
    font-weight: bold;
  }
}
</style>