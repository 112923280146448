const chats = {
    category:[
        {text:'发货',key:'fh'},
        {text:'教程',key:'jc'},
    ],
    contents:{
        fh:[
            {title:'windows下载地址',content:'sdfsddfsfsfsdfsd'},
            {title:'macos下载地址',content:'sss121121212sdfsddfsfsfsdfsd'},
        ],
        jc:[
            {title:'如何设置快捷键',content:'鼠标右键点击任何一个音效按钮，将会弹出一个菜单，点击【编辑快捷键】即可进入编辑快捷键界面，在快捷键设置模块设置 然后保存即可,注意设置快捷键时请使用英文输入法。'},
            {title:'macos下载地址',content:'sss121121212sdfsddfsfsfsdfsd'},
        ],
    }
}

export default chats